body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #050505;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}

.Tuner_tuner__QfTzd {
  /* mobile fullscreenish */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  max-width: 800px;
  margin: 5px auto;
  padding: 30px;
  color: white;
  border-radius: 1px;
  text-align: center;
  background: -webkit-radial-gradient(top, circle, rgba(0, 0, 10, 0.6) 20%, rgba(0, 0, 0, 1) 150%), url(/textured.png);
  background: radial-gradient(circle at top, rgba(0, 0, 10, 0.6) 20%, rgba(0, 0, 0, 1) 150%), url(/textured.png);
  box-shadow: inset 0 -5px 2px rgba(0, 0, 0, 0.2), inset 0 5px 2px rgba(0, 0, 0, 0.2), inset 5px 0 2px rgba(0, 0, 0, 0.2), inset -5px 0 2px rgba(0, 0, 0, 0.2);
}

.Tuner_tunerInner__1uk7y {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media (min-width: 800px) {
  .Tuner_tuner__QfTzd {
    position: relative;
    margin-top: 100px;
  }

  .Tuner_tunerInner__1uk7y {
    top: inherit;
    -webkit-transform: inherit;
            transform: inherit;
  }
}


.Tuner_screw__1_LUv {
  position: absolute;
  width: 20px;
  height: 20px;
  background: url(/screw.png);
  background-size: cover;
}

.Tuner_tuner__QfTzd h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  color: rgba(255, 255, 255, 0.4);
  letter-spacing: 1px;
  top: 30px;
  left: 40px;
}

.Tuner_currentNote__1At2N {
  color: black;
  position: relative;
  font-family: "Roboto Mono", monospace;
  margin: 30px auto 5px;
  padding: 10px 14px;
  width: 180px;
  background: #eee;
  line-height: 1;
  border-radius: 3px;
  background: -webkit-radial-gradient(circle, #fef97d, #f1ba4d);
  background: radial-gradient(circle, #fef97d, #f1ba4d);
  box-shadow: inset 0 5px 2px #dba12d, inset 0 -2px 2px #dba12d, inset 0 0 20px #dba12d, 0 0 10px #8e5a10;
}

.Tuner_currentNote__1At2N .Tuner_note__vElTo {
  text-align: left;
  letter-spacing: 5px;
  font-size: 48px;
}

.Tuner_currentNote__1At2N .Tuner_cents__3sJng {
  letter-spacing: 2px;
  text-align: right;
}

.Tuner_hz__yDbOX {
  letter-spacing: 2px;
  text-align: right;
}

.Tuner_sliderContainer__2Zk1E {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  background: -webkit-radial-gradient(circle, #fef97d, #f1ba4d);
  background: radial-gradient(circle, #fef97d, #f1ba4d);
  box-shadow: inset 0 5px 2px #dba12d, inset 0 -2px 2px #dba12d, inset 0 0 20px #dba12d, 0 0 10px #8e5a10;
}

.Tuner_sliderCenterer__2WN2- {
  -webkit-transform: translatex(50%);
          transform: translatex(50%);
}

.Tuner_slider__1dpCy {
  white-space: nowrap;
  position: absolute;
}

.Tuner_tickMark__5NtHV {
  position: absolute;
  width: 2px;
  left: -1px;
  height: 100px;
  background: red;
}

.Tuner_tuner__QfTzd canvas {
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated;
}

.Tuner_led__c2CAr {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  display: inline-block;
  margin: 10px;
}

.Tuner_powerLed__Iq9gl {
  position: absolute;
  top: 10px;
  left: 10px;
}

.Tuner_settings__1vv5f {
  margin: 20px 0 0;
}

.Tuner_tuner__QfTzd svg {
  display: inline-block;
}

select {
  -webkit-appearance: none;
}

select, input {
  background: rgba(0, 0, 0, 0.3);
  border: none;
  color: rgba(255, 255, 255, 0.2);
  padding: 5px;
  margin: 5px;
  outline: none !important;
  width: 100px;
  box-sizing: border-box;
  font-family: inherit;
}

input:focus {
  color: white;
}

input[type=number] {
  text-align: center;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
          appearance: none;
}

select option {
  background: #333;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
