.tuner {
  /* mobile fullscreenish */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  max-width: 800px;
  margin: 5px auto;
  padding: 30px;
  color: white;
  border-radius: 1px;
  text-align: center;
  background: radial-gradient(circle at top, rgba(0, 0, 10, 0.6) 20%, rgba(0, 0, 0, 1) 150%), url(/textured.png);
  box-shadow: inset 0 -5px 2px rgba(0, 0, 0, 0.2), inset 0 5px 2px rgba(0, 0, 0, 0.2), inset 5px 0 2px rgba(0, 0, 0, 0.2), inset -5px 0 2px rgba(0, 0, 0, 0.2);
}

.tunerInner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 800px) {
  .tuner {
    position: relative;
    margin-top: 100px;
  }

  .tunerInner {
    top: inherit;
    transform: inherit;
  }
}


.screw {
  position: absolute;
  width: 20px;
  height: 20px;
  background: url(/screw.png);
  background-size: cover;
}

.tuner h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  color: rgba(255, 255, 255, 0.4);
  letter-spacing: 1px;
  top: 30px;
  left: 40px;
}

.currentNote {
  color: black;
  position: relative;
  font-family: "Roboto Mono", monospace;
  margin: 30px auto 5px;
  padding: 10px 14px;
  width: 180px;
  background: #eee;
  line-height: 1;
  border-radius: 3px;
  background: radial-gradient(circle, #fef97d, #f1ba4d);
  box-shadow: inset 0 5px 2px #dba12d, inset 0 -2px 2px #dba12d, inset 0 0 20px #dba12d, 0 0 10px #8e5a10;
}

.currentNote .note {
  text-align: left;
  letter-spacing: 5px;
  font-size: 48px;
}

.currentNote .cents {
  letter-spacing: 2px;
  text-align: right;
}

.hz {
  letter-spacing: 2px;
  text-align: right;
}

.sliderContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  background: radial-gradient(circle, #fef97d, #f1ba4d);
  box-shadow: inset 0 5px 2px #dba12d, inset 0 -2px 2px #dba12d, inset 0 0 20px #dba12d, 0 0 10px #8e5a10;
}

.sliderCenterer {
  transform: translatex(50%);
}

.slider {
  white-space: nowrap;
  position: absolute;
}

.tickMark {
  position: absolute;
  width: 2px;
  left: -1px;
  height: 100px;
  background: red;
}

.tuner canvas {
  image-rendering: pixelated;
}

.led {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  display: inline-block;
  margin: 10px;
}

.powerLed {
  position: absolute;
  top: 10px;
  left: 10px;
}

.settings {
  margin: 20px 0 0;
}

.tuner svg {
  display: inline-block;
}

select {
  -webkit-appearance: none;
}

select, input {
  background: rgba(0, 0, 0, 0.3);
  border: none;
  color: rgba(255, 255, 255, 0.2);
  padding: 5px;
  margin: 5px;
  outline: none !important;
  width: 100px;
  box-sizing: border-box;
  font-family: inherit;
}

input:focus {
  color: white;
}

input[type=number] {
  text-align: center;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  appearance: none;
}

select option {
  background: #333;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}